
import { Dialog } from '@mui/material';

interface SecurityUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onNeverShowAgain: () => void;
}

const SecurityUpdateModal: React.FC<SecurityUpdateModalProps> = ({ isOpen, onClose, onNeverShowAgain }) => {
  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        className: "border-4 border-brand-orange p-8 rounded-xl max-w-2xl mx-4 "
      }}
    >
      <div className=" rounded-lg p-4">
        <h2 className="text-3xl font-semibold mb-4 text-center text-brand-orange">
          Sloane has had a security update
        </h2>
        <p className="mb-4 text-center text-lg">
          She is back up and running again 😎
        </p>
        {/* add a line */}
        <div className="border-b-2 border-brand-orange my-4"></div>
        <div className="mb-6">
          <p className="font-medium mb-2 text-md">Please follow these steps to log in:</p>
          <ol className="list-decimal list-inside space-y-2 text-brand-green-dark ml-8">
            <li>Enter your email</li>
            <li>Click forgot password</li>
            <li>Click the black button 'Reset your password'</li>
          </ol>
        </div>

        <div className="mb-6">
          <p className="mb-2">Then please check both your inbox and junk box for a 6 digit code from sloane.</p>
          <p>Enter the code and choose the password you would like.</p>
          <p className="italic mt-2">This can be the exact password you had previously it does not have to be a new one.</p>
        <div className="border-b-2 border-brand-orange my-4"></div>
          <p className="mt-2 text-center">Thank you for your patience 🙏</p>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
          <button
            onClick={onNeverShowAgain}
            className="px-6 py-2 bg-brand-green text-white rounded-lg hover:bg-opacity-90 transition-colors"
          >
            I've Already Changed My Password
          </button>
          <button
            onClick={onClose}
            className="px-6 py-2 border border-brand-green text-brand-green rounded-lg hover:bg-brand-green hover:text-white transition-colors"
          >
            Thanks, I'll Do This Now
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default SecurityUpdateModal; 